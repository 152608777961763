// App internal variables
@main-header-height: 82px;
@main-nav-width: 86px;
@main-nav-width-collapse: 225px;

body.ss-dashboard-wrapper {
	background-color: #f7f9ff;
	div.dash-header,
	div.dash-nav,
	div.dash-footer {
		display: none !important;
	}

	.ss-help-link {
		display: none !important;
	}

	&.newui .dashboard .pagecontent {
		background-color: #f7f9ff !important;
		height: auto;
		overflow: inherit;
	}

	&.newui .dashboard .pagewrapper {
		height: auto;
		padding: 0 !important;
	}

	.v-application {
		min-height: inherit;
	}

	.section {
		min-width: auto;
	}

	&.newui {
		&.leave {
			#uimodalbg {
				z-index: 88;
			}

			#uimodal {
				z-index: 89;
			}
		}

		.UIPromtBox,
		.UIPromtBox.master {
			z-index: 99 !important;
		}
		.UIPromtBoxBG {
			z-index: 99 !important;
		}
	}

	@media screen and (min-width: 1200px) {
		div.pagecontent {
			padding: @main-header-height 0 0 @main-nav-width !important;
			background-color: #f7f9ff;
		}

		&.menu-show-full div.pagecontent {
			padding-left: @main-nav-width-collapse !important;
		}
	}

	@media screen and (min-width: 768px) {
		div.pagecontent {
			padding-top: @main-header-height !important;
		}
	}

	@media screen and (min-width: 1200px) {
		.contentwrapper {
			margin: 0 !important;
			padding: 20px !important;
		}
	}

	.contentwrapper {
		margin: 12px !important;

		@media screen and (max-width: 767px) {
			padding-top: 56px;
		}
	}

	.toaster {
		top: @main-header-height;
	}

	.uimodalbox,
	#uimodalbg,
	.uimodalbg,
	.uimodalbox .uimodalcontainer {
		height: 100%;
		width: 100%;
	}

	@media screen and (min-width: 992px) {
		&.menu-show-full {
			#uimodalbg,
			.uimodalbg,
			.uimodalbox,
			.uimodalbox .uimodalcontainer {
				width: 100%;
			}
		}
	}

	// Schedules
	.schedules {
		margin-bottom: 24px;
		.sch-nav {
			background-color: transparent;
			border: 0;
		}

		.sch-heading-text {
			font-family: Arial, sans-serif, Helvetica Neue;
			font-weight: 400;
			line-height: 1.25em;
			color: #344356;

			@media screen and (min-width: 1601px) {
				font-size: 26px;
			}

			@media screen and (max-width: 1600px) {
				font-size: 20px;
			}

			@media screen and (max-width: 767px) {
				font-size: 18px;
			}
		}
	}

	// BOX SHADOWS
	.shadow {
		box-shadow: 0 5px 20px #00378033;
	}
}
