.ss-dashboard-wrap-main-header {
    font-family: "Montserrat", sans-serif;
    position: fixed;
    height: 82px;
    border-bottom: 1px solid @light-gray1;
    height: @main-header-height;
    top: 0;
    left: 0;
    right: 0;
    background-color: @white1;
    z-index: @main-header-zindex;

    > div {
        padding: 12px 36px;
        display: flex;
        width: 100%;
        height: @main-header-height;
        justify-content: space-between;
        align-items: center;
    }

    h1 {
        font-size: 20px;
        line-height: 20px;
        margin: 0;
    }

    .menu-burger {
        color: @black2;
        margin-right: 12px;
        margin-left: -8px;
        vertical-align: middle;
    }

    .burger-menu-ico {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        width: 24px;
        position: relative;
        vertical-align: middle;

        &::before,
        &::after {
            content: " ";
            display: block;
            position: absolute;
            right: 2px;
            left: 2px;
            box-sizing: content-box;

        }

        &::before {
            top: 5px;
            border-top: @black2 solid 3px;
        }

        &::after {
            height: 3px;
            top: 12px;
            border-top: @black2 solid 3px;
            border-bottom: @black2 solid 3px;
        }
    }

    @media screen and (max-width: @screen-xl) {
        > div { padding: 12px 20px; }
    }
}

.ss-dashboard-wrap-user-top-nav {
    white-space: nowrap;
    margin-right: -8px;

    > * {
        vertical-align: middle;
    }

    .help-btn {
        color: @black2;
    }
}

.ss-dashboard-wrap-user-name {
    font-family: "Montserrat", sans-serif;
    text-align: right;
    width: 50vw;
    max-width: 120px;
    padding-right: 8px;
    white-space: nowrap;

    .ss-dashboard-wrap-user-dd {
        line-height: 12px;
        cursor: pointer;
        > * {
            vertical-align: middle;
        }
    }
}

.ss-dashboard-wrap-dd-profile {
    min-width: 125px;
    overflow-x: auto;
    @media screen and (min-width: @screen-xs) {
        max-height: 320px;
    }

    a {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        color: @black;
        width: 220px;
        display: block;
        white-space: nowrap;
        > * {
            vertical-align: middle;
            display: inline-block;
        }
        svg {
            margin-right: 8px;
        }
        span {
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100% - 24px);
        }
    }
}

@media screen and (min-width: @screen-md-min) {
    .ss-dashboard-wrap-mobile-name {
        display: none;
    }
}

@media screen and (max-width: @screen-sm-max) {
    .ss-dashboard-wrap-user-dd,
    .ss-dashboard-wrap-main-header h1 > span {
        display: none;
    }

    .ss-dashboard-wrap-user-top-nav .help-btn {
        color: @white1;
    }

    .ss-dashboard-wrap-main-header > div * {
        vertical-align: middle;
    }

    .ss-dashboard-wrap-main-header h1,
    .ss-dashboard-wrap-main-header > div > div {
        display: inline-block;
    }

    .ss-dashboard-wrap-main-header,
    .ss-dashboard-wrap-main-header > div {
        height: 52px;
    }
    .ss-dashboard-wrap-main-header {
        background: linear-gradient(90deg, #1268b3 30%, #01abec 100%);

        .menu-burger {
            min-width: 32px;
            padding: 0;
            width: 32px;
            margin-right: 4px;
            margin-left: 0;
            color: @white1;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    .ss-dashboard-wrap-main-header {
        > div {
            display: flex;
            padding: 12px;
            > div {
                flex-grow: 1;
            }
        }
    }

    .ss-dashboard-wrap-user-top-nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .ss-dashboard-wrap-user-top-nav .ss-dashboard-wrap-btn:last-child {
        padding: 0;
        margin-left: 0 !important;

        svg {
            height: 22px;
        }
    }

    .ss-dashboard-wrap-user-name {
        font-size: 20px;
        color: @white1;
        line-height: 20px;
        font-weight: 600;
        width: calc(100% - 40px);
        max-width: 100%;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

@media screen and (max-width: @screen-xs) {
    .ss-dashboard-wrap-main-header {
        padding: 8px 12px;
        > div {
            padding: 0;
            align-items: normal;
        }
    }

    .ss-dashboard-wrap-user-name {
        font-size: 16px;
    }
}
