.ss-dashboard-wrap-navigator {
    position: fixed;
    left: 0;
    top: @main-header-height;
    bottom: 0;
    background-color: @blue;
    background: linear-gradient(180deg, #1268b3 30%, #01abec 100%);
    width: @main-nav-width;
    z-index: @main-menu-zindex;
    transition: 200ms all ease-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    body.menu-show-full & {
        .ss-dashboard-wrap-nav-item {
            overflow: inherit;
            .ss-dashboard-wrap-nav-item-label {
                opacity: 1;
                margin-left: 0;
                width: auto;
            }

            .ss-dashboard-wrap-sub-link-arrow {
                width: 16px;
                height: 16px;
            }
        }
    }

    @media screen and (min-width: (@screen-xl + 1)) {
        .ss-dashboard-wrap-navigator-close {
            display: none;
        }

        body.menu-show-full & {
            width: @main-nav-width-collapse;
            .ss-dashboard-wrap-nav-item {
                svg {
                    left: 4px;
                }
                &.active svg {
                    left: 8px;
                }
            }
        }
    }

    @media screen and (min-width: @screen-md) {
        .ss-dashboard-wrap-navigator-close {
            display: none;
        }
    }

    @media screen and (max-width: @screen-xl-min) {
        width: @main-nav-width-collapse;
        transform: translateX(-@main-nav-width-collapse - 2);

        body.menu-show-full & {
            transform: translateX(0);
            z-index: 999;
        }
    }

    @media screen and (max-width: @screen-sm-max) {
        width: 100vw;
        transform: translateX(-100vw);
        top: 0;
        padding: 24px 24px 12px;
        background: linear-gradient(348.86deg, #f0f4fd -56.86%, #ffffff 126.39%);

        .ss-dashboard-wrap-navigator-close {
            position: absolute;
            top: 12px;
            right: 12px;
            padding: 0;
            width: 36px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            min-width: 36px;
        }

        &::before {
            display: block;
            content: "Menu";
            font-size: 24px;
            font-weight: 600;
        }
    }
}

.ss-dashboard-wrap-navigator-wrapper {
    position: relative;
    height: 100%;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    @media screen and (max-width: @screen-sm-max) {
        height: calc(100vh - 56px);
        margin: 0 -24px;
        overflow-y: auto;
        overflow-x: hidden;

        > div:not(.ss-dashboard-wrap-collapse-btn) {
            border-top: 1px solid @border-color-base;
            &:first-child {
                border-top: 0;
            }
        }
    }

    .link-item-wrapper {
        padding-left: 16px;
        padding-right: 16px;

        &:last-child {
            margin-bottom: 12px;
        }

        &:first-of-type {
            margin-top: 12px;
        }
    }
}

.ss-dashboard-wrap-nav-item {
    position: relative;
    display: block;
    padding: 12px 0 12px 36px;
    font-family: @font-family;
    font-size: 14px;
    color: @white1;
    white-space: nowrap;
    margin-bottom: 12px;

    svg:not(.menu-edge) {
        position: absolute;
        top: 8px;
        left: 12px;
        transition: left 200ms ease-out;
    }

    .ss-dashboard-wrap-sub-link-arrow {
        position: absolute;
        top: 12px;
        right: 0;
        display: inline-block;
        height: 12px;
        width: 12px;

        > svg {
            position: static;
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (max-width: @screen-sm-max) {
        color: @blue;

        &:hover {
            color: darken(@blue, 15%) !important;
        }

        svg:not(.menu-edge) {
            left: 24px;
        }
        .ss-dashboard-wrap-sub-link-arrow {
            top: 18px;
            right: 16px;
        }

        &:hover {
            color: darken(@blue, 15%) !important;
        }
    }

    @media screen and (max-width: @screen-sm-max) {
        svg:not(.menu-edge) {
            top: 12px;
        }
    }

    .ss-dashboard-wrap-nav-item-label {
        transition: all 200ms ease-out;
        opacity: 0;
        font-weight: 700;
        display: inline-block;
        width: 0px;
        margin-left: -24px;
    }

    &:hover {
        color: @white1;
    }

    &.active {
        background-color: @white2;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        margin-right: -16px;
        padding-left: 44px;
        color: @blue;

        svg:not(.menu-edge) {
            left: 12px;
        }

        .ss-dashboard-wrap-nav-sub-links > .ss-dashboard-wrap-nav-item {
            padding-left: 40px;
        }

        &:hover {
            text-shadow: none;
        }

        .ss-dashboard-wrap-nav-sub-links {
            opacity: 1;
        }

        .ss-dashboard-wrap-nav-item-top-edge,
        .ss-dashboard-wrap-nav-item-bottom-edge {
            opacity: 1;
        }

        .ss-dashboard-wrap-sub-link-arrow {
            right: 16px;
            transform: rotate(180deg);
        }

        &:not(.sub-link-item) {
            margin-left: -8px;
        }
    }

    @media screen and (max-width: @screen-xl) {
        body.menu-show-full & {
            padding-left: 40px;

            svg:not(.menu-edge) {
                left: 8px;
            }
        }

        body.menu-show-full &.active {
            margin-right: 0;
            border-radius: 10px;
            padding-left: 44px;

            & svg:not(.menu-edge) {
                left: 12px;
            }

            .ss-dashboard-wrap-nav-sub-links .ss-dashboard-wrap-nav-item {
                padding-left: 44px;
            }
        }
    }

    @media screen and (max-width: @screen-sm-max) {
        padding: 16px 24px 16px 60px;
        margin-bottom: 0;

        .ss-dashboard-wrap-nav-item-label {
            font-size: 16px;
            font-weight: normal;
        }
    }
    .ss-dashboard-wrap-nav-item-top-edge .ss-dashboard-wrap-collapse-header {
        margin-bottom: 0 !important;
    }
}

div.ss-dashboard-wrap-nav-item {
    transition: height 300ms ease-in-out;
    > div:not(.ss-dashboard-wrap-nav-sub-links) {
        cursor: pointer;
    }
}
.ss-dashboard-wrap-nav-sub-links {
    position: absolute;
    top: 42px;
    left: 0;
    opacity: 0;

    .ss-dashboard-wrap-nav-item {
        margin-bottom: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        color: @gray7;

        svg {
            top: 4px;
        }
    }

    > a:hover,
    > a.active {
        color: @black;
        text-decoration: underline;
    }
}

.ss-dashboard-wrap-nav-item-top-edge,
.ss-dashboard-wrap-nav-item-bottom-edge {
    position: absolute;
    right: 0;
    width: 10px;
    height: 10px;
    line-height: 10px;
    background-repeat: no-repeat;
    z-index: 10;
    opacity: 0;

    @media screen and (max-width: @screen-xl) {
        display: none;
    }
}

.ss-dashboard-wrap-nav-item-top-edge {
    bottom: 100%;
}

.ss-dashboard-wrap-nav-item-bottom-edge {
    top: 100%;
    background-position: -10px 0;
}

.ss-dashboard-wrap-collapse-btn {
    padding: 0 16px 12px;
    @media screen and (min-width: @screen-md-min) {
        width: 100%;
    }
    @media screen and (max-width: @screen-sm-max) {
        margin: 0 -24px;
    }
    > a {
        margin-bottom: 0;
    }
}

@media screen and (max-width: @screen-sm-max) {
    .ss-dashboard-wrap-collapse-btn {
        display: none;
    }
}

@media screen and (max-width: @screen-md-max) {
    body.menu-show-full {
        overflow: hidden;
    }
}

#dbwEventHandler {
    height: 1px;
    width: 1px;
    position: fixed;
    visibility: hidden;
    opacity: 0;
}
