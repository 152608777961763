.ss-dashboard-wrap-collapse {
	border-radius: 10px;
	border-color: transparent;

	.ss-dashboard-wrap-collapse-header {
		font-size: 16px;
		font-weight: bold;

		.detail-marker();
	}

	> .ss-dashboard-wrap-collapse-item {
		border-bottom: 0;

		&.ss-dashboard-wrap-collapse-item-active
			.ss-dashboard-wrap-collapse-header
			> .symbol-block {
			border-radius: 10px 0 0 0;
		}
	}

	&.ss-dashboard-wrap-collapse-icon-position-right
		> .ss-dashboard-wrap-collapse-item
		> .ss-dashboard-wrap-collapse-header {
		padding-left: 36px;
		&::before {
			background-color: transparent;
		}
		svg {
			position: absolute;
			right: 12px;
			top: 50%;
			margin-top: -12px;
		}
	}

	&.ss-dashboard-wrap-collapse-ghost {
		.ss-dashboard-wrap-collapse-header {
			padding: 0 !important;
			margin-bottom: 12px;
		}

		.ss-dashboard-wrap-collapse-content {
			background-color: @white1;
		}

		> .ss-dashboard-wrap-collapse-item
			> .ss-dashboard-wrap-collapse-header
			svg {
			right: 0;
		}
	}

	> .ss-dashboard-wrap-collapse-item
		> .ss-dashboard-wrap-collapse-header
		.ss-dashboard-wrap-collapse-extra {
		padding-right: 12px;
	}
}

.ss-dashboard-wrap-collapse-content {
	position: relative;
}

.ss-dashboard-wrap-btn {
	@media screen and (max-width: @screen-sm-max) {
		padding: 6px 12px;
		line-height: 20px;
		font-size: 14px;
		height: 32px;
	}
}
