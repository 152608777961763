.ss-payroll-dashboard-content {
    padding: 1rem 1rem;
    height: 100vh;
    background: #f2f2f2;
    left: 225px;
    position: absolute;
    top: 80px;
    flex-grow: 1;
    width: calc(100% - 225px);
    height: calc(100% - 80px);
}

.flex-grow-1 {
    flex-grow: 1;
}

.ss-payroll-d-table {
    background: white;
    min-height: 400px;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
}

.ss-input-search-button {
    background: white;
    border: 1px solid #c8d1db;
}

.card-table-heading {
    background: #23588a;
    color: white;
    padding: 4px 8px;
}

.card-table-row {
    padding: 2px 8px;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-cap {
    text-transform: capitalize;
}

.text-right {
    text-align: right;
}

.btn-flat {
    line-height: 1;
    padding: 2px 10px;
}

.input-flat {
    line-height: 1;
    height: 24px;
}

.grid-filter {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    margin: 10px 0;
    border: 1px solid #c8d1db;
    border-radius: 5px;
}
@ant-prefix: ss;@border-radius-base: 5px;@border-color-base: #c8d1db;@btn-default-color: #23588a;@btn-default-bg: #fff;@btn-default-border: #1268b3;@btn-primary-bg: #44b5ec;@input-bg: #fff;@input-color: #000;@input-disable-color: #bdbdbd;@input-disabled-bg: #f7f9fe;@select-dropdown-line-height: 32px;@select-dropdown-height: 32px;