.ss-dashboard-wrap-loader {
	position: fixed;
	width: 120px;
	height: 60px;
	z-index: 100;

	&.ss-dashboard-wrap-middle-loader {
		left: 50%;
		top: 50%;
		margin-top: -30px;
		margin-left: -60px;
	}

	.ss-dashboard-wrap-spin-text {
		margin-top: 12px;
	}

	&.app-wrapper::before {
		content: " ";
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: @white2;
	}
}

.ss-dashboard-wrap-loader-overlay {
	background-color: @white2;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99999;

	> div {
		height: 100vh;
		width: 100vw;
		position: relative;
	}
}

.ss-dashboard-wrap-body {
	min-height: 100vh;
	transition: 200ms all ease-in;

	body.ss-dashboard-wrapper & {
		padding-top: @main-header-height;
		padding-left: @main-nav-width;
	}
}

body.ss-dashboard-wrapper {
	.ss-dashboard-wrap-body {
		@media screen and (max-width: @screen-md-max) {
			padding-left: 0 !important;
		}

		@media screen and (max-width: @screen-sm-max) {
			padding-top: 52px;
		}
	}

	&.menu-show-full {
		.ss-dashboard-wrap-body {
			padding-left: @main-nav-width-collapse;
		}
	}

	@media screen and (min-width: (@screen-xl + 1)) {
		.ss-dashboard-wrap-body {
			padding-left: @main-nav-width-collapse;
		}
	}
}

.ss-dashboard-wrap-wrap {
	position: relative;
	padding: 32px 40px;
	min-height: ~"calc(100vh - @{main-header-height})";
	@media screen and (min-width: @screen-md) and (max-width: @screen-lg-max) {
		padding: 24px;
	}

	@media (max-width: @screen-sm-max) {
		padding: 20px;
	}
}

.ss-dashboard-wrap-body-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;

	> h3 {
		font-size: 26px;
		line-height: 1.25em;
		font-weight: 400;
	}
	@media screen and (max-width: @screen-sm-max) {
		margin: -24px -20px 20px;
		padding: 12px 20px;

		background-color: @white1;
		h3 {
			font-size: 16px;
			font-weight: bold;
			color: @blue;
		}
	}
}

.ss-dashboard-wrap-body-header-toolbar {
	.ss-dashboard-wrap-btn {
		@media screen and (min-width: @screen-md) {
			min-width: 175px;
			margin-left: 12px;
			box-shadow: 0px 5px 20px rgba(0, 55, 128, 0.2);
		}

		@media screen and (max-width: @screen-sm-max) {
			min-width: 100px;
			margin-left: 8px;
			padding: 0 16px;
		}
	}
}
