/*
 * Generate Margin Class
 * margin, margin-top, margin-bottom, margin-left, margin-right
 */

.margin (@label, @size: 1, @key: 1, @increment: 4) when (@size =< 40) {
	.m-@{key} {
		margin: @size !important;
	}

	.m-t-@{key} {
		margin-top: @size !important;
	}

	.m-b-@{key} {
		margin-bottom: @size !important;
	}

	.m-l-@{key} {
		margin-left: @size !important;
	}

	.m-r-@{key} {
		margin-right: @size !important;
	}

	.margin(@label - @increment; @size + @increment; @key + @increment; @increment);
}

.margin(80, 0px, 0);

/*
 * Generate Padding Class
 * padding, padding-top, padding-bottom, padding-left, padding-right
 */

.padding (@label, @size: 1, @key:1) when (@size =< 80) {
	.p-@{key} {
		padding: @size !important;
	}

	.p-t-@{key} {
		padding-top: @size !important;
	}

	.p-b-@{key} {
		padding-bottom: @size !important;
	}

	.p-l-@{key} {
		padding-left: @size !important;
	}

	.p-r-@{key} {
		padding-right: @size !important;
	}

	.padding(@label - 4; @size + 4; @key + 4);
}

.padding(80, 0px, 0);

/*
 * Generate Font-Size Classes (8px - 36px)
 */
.font-size (@label, @size: 8, @key:10) when (@size =< 36) {
	.f-@{key} {
		font-size: @size !important;
	}

	.font-size(@label - 1; @size + 1; @key + 1);
}

.font-size(36, 8px, 8);

/*
 * Colors variations for
 * Font color and BG color
 */
@length: length(@colors);
.color-variation(@names; @colors; @index)
	when
	(iscolor(extract(@colors, @index)))
	and
	(@index > 0) {
	.color-variation(@names; @colors; (@index - 1)); // decrement.

	@name: extract(@names, @index);
	@color: extract(@colors, @index);

	.c-@{name} {
		color: @color !important;
	}

	.bg-@{name} {
		background-color: @color !important;
	}
}

.color-variation(@names; @colors; @length);

/*
 * Font Align
 */
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}

.text-ellipsis {
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*
 * Font Weight
 */
.f-bold {
	font-weight: bold !important;
}
.f-medium {
	font-weight: 500 !important;
}
.f-normal {
	font-weight: normal !important;
}

/*
  * Position Classes
  */
.p-relative {
	position: relative !important;
}
.p-absolute {
	position: absolute !important;
}
.p-fixed {
	position: fixed !important;
}
.p-static {
	position: static !important;
}

/*
  * Overflow
  */
.o-hidden {
	overflow: hidden !important;
}
.o-visible {
	overflow: visible !important;
}
.o-auto {
	overflow: auto !important;
}

/*
  * Display
  */
.d-block {
	display: block !important;
}
.d-iblock {
	display: inline-block !important;
}
.d-flex {
	display: flex;
}

/*
  * flex rules
  */
.flex-acenter {
	align-items: center;
}
.flex-astart {
	align-items: flex-start;
}
.flex-aend {
	align-items: flex-end;
}
.flex-astretch {
	align-items: stretch;
}

.flex-jcenter {
	justify-content: center;
}
.flex-jbetween {
	justify-content: space-between;
}
.flex-jevenly {
	justify-content: space-evenly;
}

/*
 * Vertical Alignment
 */
.va-middle {
	vertical-align: middle;
}
.va-sub {
	vertical-align: sub;
}
.va-top {
	vertical-align: top;
}
.va-bottom {
	vertical-align: bottom;
}
.va-baseline {
	vertical-align: baseline;
}

.ss-dashboard-wrap-box {
	background-color: @white1;
	border-radius: 6px;
}

// BOX SHADOWS
.shadow {
	box-shadow: 0 5px 20px #00378033;
}

.shadow-white {
	box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.2);
}

.detail-marker {
	position: relative;
	.symbol-block {
		border-radius: 10px 0px 0px 10px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 12px;
		background-color: @white1;
	}
}

.user-select-off {
	-webkit-user-select: none; /* Chrome/Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */
	-o-user-select: none;
	user-select: none;
}
